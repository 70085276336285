import linkifyHtml from "linkifyjs/html";
import escape from "lodash/escape";
import isElement from "lodash/isElement";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import size from "lodash/size";
import trim from "lodash/trim";
import toString from "lodash/toString";
import DOMPurify from "dompurify";

const { Node, XMLSerializer } = window;

DOMPurify.addHook("afterSanitizeAttributes", node => {
    if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener noreferrer");
    }
});

export function isNodeInRoot(node, root) {
    let innerNode = node;

    while (innerNode) {
        if (innerNode === root) {
            return true;
        }
        innerNode = innerNode.parentNode;
    }
    return false;
}

export const resetModalStyle = {
    content: {
        background: null,
        border: null,
        borderRadius: null,
        bottom: null,
        left: null,
        outline: null,
        overflow: null,
        padding: null,
        position: null,
        right: null,
        top: null,
        WebkitOverflowScrolling: null,
    },
    overlay: {
        backgroundColor: null,
        bottom: null,
        left: null,
        position: null,
        right: null,
        top: null,
    },
};

export function pluralize(word, amount) {
    return amount === 1 ? `${word}` : `${word}s`;
}

export function checkForWords(text, words) {
    const wordsFound = [];

    for (let i = 0; i < words.length; i += 1) {
        if (text.toLowerCase().indexOf(words[i].toLowerCase()) > -1) {
            wordsFound.push(words[i]);
        }
    }

    return wordsFound;
}

// TODO: refactor function to be pure
export function getQueryParam(paramName) {
    const param = paramName.replace(/[[]/, `\\[`).replace(/[\]]/, `\\]`);
    const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    let value;

    if (results) {
        value = decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    return value;
}

export function getDocumentHeight() {
    const { body, documentElement: html } = document;

    return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
}

export function getOffset(node) {
    let element = node;
    const offset = {
        left: 0,
        top: 0,
    };

    // eslint-disable-next-line no-cond-assign
    do {
        if (!Number.isNaN(element.offsetLeft) && !Number.isNaN(element.offsetTop)) {
            offset.left += element.offsetLeft;
            offset.top += element.offsetTop;
        }
    } while ((element = element.offsetParent) !== null);

    return offset;
}

export function getElementWidthWithMargins(node) {
    const nodeStyle = window.getComputedStyle(node);
    const marginLeft = parseInt(nodeStyle.marginLeft, 10);
    const marginRight = parseInt(nodeStyle.marginRight, 10);
    const width = parseInt(nodeStyle.width, 10);

    return width + marginLeft + marginRight;
}

export function getElementHeightWithMargins(node) {
    const nodeStyle = window.getComputedStyle(node);
    const marginBottom = parseInt(nodeStyle.marginBottom, 10);
    const marginTop = parseInt(nodeStyle.marginTop, 10);
    const height = parseInt(nodeStyle.height, 10);

    return height + marginBottom + marginTop;
}

export function buildJsonUrlFor(location) {
    const { hash, host, pathname, protocol, search } = location;

    return pathname !== "/" ? `${protocol}//${host}${pathname}.json${search}${hash}` : undefined;
}

export function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let isMobile = false;

    if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            userAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            userAgent.substr(0, 4)
        )
    ) {
        // eslint-disable-line max-len
        isMobile = true;
    }

    return isMobile;
}

export function validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len

    return regex.test(email);
}

/**
 * Super rudimentary - assumes country code 001 and valid numbers for that code.  Using:
 *
 * https://github.com/catamphetamine/libphonenumber-js
 *
 * is the logical next step
 */
export function validatePhone(phone) {
    if (phone) {
        const numerals = phone.toString().replace(/\D/g, "");
        return numerals.length === 10;
    }

    return false;
}

export function postMessageToParent(command, params) {
    const referrer = decodeURIComponent(document.referrer) || "*";

    window.parent.postMessage({ command, params }, referrer);
}

/**
 * Convert line breaks, BB code tags ([code][/code]) and links to HTML
 *
 * @param {String} text we want to format
 * @returns {String} Formatted text
 *
 * Using regexForLineBreaksAfterCodeTag to remove any line breaks after the
 * opening [code] tag.
 * Using regexForPreTagBeforeLineBreak to remove an extra <br> tag that gets
 * introduced after the closing </pre> tag.
 */
export function formatStructuredText(text) {
    if (isEmpty(text)) {
        return undefined;
    }

    const regexForCode = /\[code\]([\s\S]*?)\[\/code\]/g;
    const regexForLineBreaks = /\r\n|\r|\n/g;
    const regexForLineBreaksAfterCodeTag = /^\s+|\s+$/g;
    const regexForPreTagBeforeLineBreak = /<\/pre>(\r\n|\r|\n)/g;

    const formattedText = escape(text)
        .replace(regexForCode, (match, p1) => {
            let codeStr;

            if (regexForLineBreaks.test(p1)) {
                codeStr = `<pre class="code-snippet"><code>`;
                codeStr += p1.replace(regexForLineBreaksAfterCodeTag, "").replace(regexForLineBreaks, "<br>");
                codeStr += `</code></pre>`;
            } else {
                codeStr = `<code class="code-snippet">${p1}</code>`;
            }

            return codeStr;
        })
        .replace(regexForPreTagBeforeLineBreak, () => "</pre>")
        .replace(regexForLineBreaks, "<br>");

    return linkifyHtml(formattedText);
}

export function createSanitizedMarkup(html) {
    return { __html: DOMPurify.sanitize(html) };
}

/**
 * Forms a possessive noun
 *
 * @param {String} noun
 * @returns {String} Possessive noun
 */
export function possessive(noun) {
    const endsWithS = noun.toLowerCase().slice(-1) === "s";

    return endsWithS ? `${noun}'` : `${noun}'s`;
}

export function windowIsVisible() {
    if ("hidden" in document) {
        return !document.hidden;
    }
    if ("mozHidden" in document) {
        return !document.mozHidden;
    }
    if ("webkitHidden" in document) {
        return !document.webkitHidden;
    }
    if ("msHidden" in document) {
        return !document.msHidden;
    }
    return true;
}

/**
 * Detect if element is scrolled to bottom
 *
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function isScrolledToBottom(element) {
    return element.scrollTop + element.offsetHeight === element.scrollHeight;
}

/**
 * Detect if browser is Microsoft Edge
 *
 * @returns {boolean}
 */
export function isEdgeBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor;

    return /Edge\/\d+/.test(userAgent);
}
/**
 * Detect if browser is Internet Explorer 11
 *
 * @returns {boolean}
 */
export function isInternetExplorer11() {
    const userAgent = navigator.userAgent || navigator.vendor;

    return /Trident\/7\./.test(userAgent);
}

/**
 * Detect if OS is Android
 *
 * @returns {boolean}
 */
export function isAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor;

    return /Android [4-7]/.test(userAgent);
}

/**
 * Detect if script is executed within an iframe
 *
 * @returns {boolean}
 */
export function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (exception) {
        return true;
    }
}

/**
 * Insert string at caret position of the input field
 * @returns {string}
 */
export function insertAtCaretPos(input, text) {
    input.focus();

    return `${input.value.substring(0, input.selectionStart)} ${text} ${input.value.substring(
        input.selectionEnd,
        input.value.length
    )}`;
}

/**
 * A polyfill to make innerHTML work on SVG elements on IE11.
 *
 * @returns {string}
 */
export function getInnerHtmlOfSvgElement(svgElement) {
    const XMLS = new XMLSerializer();

    return Array.prototype.slice
        .call(svgElement.childNodes)
        .map(node => (node.nodeType === Node.ELEMENT_NODE ? XMLS.serializeToString(node) : undefined))
        .join();
}

export function getReactDisplayName(Component) {
    return Component.displayName || Component.name || "Component";
}

export function getTextFromNode(node) {
    if (!node) return undefined;

    const span = document.createElement("span");
    const regexForLineBreakTags = /(<br\s*\/?>){3,}/gi;
    const regexForLineBreaks = /(\r\n|\r|\n){3,}/gi;

    if (isString(node) || isNumber(node)) {
        span.innerHTML = toString(node).replace(regexForLineBreakTags, " ");
    } else if (isElement(node)) {
        span.innerHTML = node.innerText.replace(regexForLineBreaks, " ");
    }

    return span.textContent || span.innerText;
}

export function createElementFromString(string) {
    const wrapper = document.createElement("div");

    wrapper.innerHTML = trim(string);

    return wrapper.firstChild;
}

export function isValidUuid(string) {
    if (!isString(string)) return false;

    return size(string.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) > 0;
}
