import assign from "lodash/assign";
import first from "lodash/first";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import isNil from "lodash/isNil";
import isFunction from "lodash/isFunction";
import last from "lodash/last";
import values from "lodash/values";
import URI from "urijs";

import constants from "../constants/RTMEmbed";
import { isMobileDevice } from "../utils/Utils";
import { Public as PublicAPI } from "../constants/api";
import { PostMessage } from "../constants/chat";

// TODO: refactor to factory
export default class BrowserSupported {
    constructor() {
        this.config = { id: undefined };
        this.isWidgetReady = false;
        this.commandQueue = {
            [PublicAPI.GET_STATE]: undefined,
            [PublicAPI.REROUTE]: undefined,
            [PublicAPI.ON_ASK_QUESTION]: [],
            [PublicAPI.ON_LIST]: [],
            [PublicAPI.ON_READY]: [],
            [PublicAPI.ON_NAVIGATE]: [],
            [PublicAPI.ON_RATED]: [],
            [PublicAPI.ON_MESSAGE]: [],
            [PublicAPI.ON_REJECTION]: [],
            [PublicAPI.ON_REROUTE]: [],
            [PublicAPI.ON_SOLVED]: [],
        };
        this.iframe = undefined;
        this.locationQueryParams = URI.parseQuery(window.location.search);
        this.scrollPosition = 0;
        this.wrapper = undefined;

        this.postMessageToWidget = this.postMessageToWidget.bind(this);
        this.registerCommandInQueue = this.registerCommandInQueue.bind(this);

        forEach(window[constants.NAMESPACE].cq, this.onPublicAPICommand.bind(this));
        window[constants.NAMESPACE] = (...params) => {
            this.onPublicAPICommand.call(this, params);
        };
        window.addEventListener("message", this.onProcessPostMessage.bind(this));
    }

    createIframe() {
        if (!this.config.id) {
            throw new Error(
                `Configuration id not provided. Set the configuration id using the ${constants.NAMESPACE}('config', { id: YOUR_ID }) command.`
            );
        }

        this.wrapper = document.createElement("div");
        this.wrapper.className = "directly-rtm is-hidden";

        this.iframe = document.createElement("iframe");
        this.iframe.frameBorder = 0;
        this.iframe.height = 0;
        this.iframe.width = 0;
        this.iframe.referrerPolicy = "no-referrer-when-downgrade";

        this.iframe.src = `${this.baseUrl}/chat?cfgId=${this.config.id}${this.additionalQueryParameters}`;
        this.iframe.onload = this.onLoad.bind(this);
        this.wrapper.appendChild(this.iframe);
        document.body.appendChild(this.wrapper);
    }

    createStyles() {
        const style = document.createElement("link");
        const head = document.head || document.getElementsByTagName("head")[0];

        style.type = "text/css";
        style.rel = "stylesheet";
        style.crossOrigin = "anonymous";
        style.href = `${this.baseUrl}/widgets/rtm/style.css?v=${__webpack_hash__}`; // eslint-disable-line camelcase
        head.appendChild(style);
    }

    cleanupLandingUrl() {
        if (this.hasLandingQueryParam) {
            const uri = new URI(window.location);

            uri.removeQuery(constants.LANDING_URL_KEY);
            uri.removeQuery(constants.EMAIL_TRACKING_PARAMETER);
            window.history.replaceState({}, "", uri.toString());
        }
    }

    onProcessPostMessage({ data: { command, params }, origin, source }) {
        if ((origin !== this.baseUrl && source !== this.iframe.contentWindow) || !command) return;

        if (includes(values(PostMessage), command)) {
            this[command].call(this, params);
        }
    }

    onLoad() {
        this.postMessageToWidget(PostMessage.IFRAME_LOADED, this.config);
        if (this.hasLandingQueryParam) {
            this.postMessageToWidget(PostMessage.NAVIGATE, [this.locationQueryParams[constants.LANDING_URL_KEY]]);
        }
        this.isWidgetReady = true;
    }

    postMessageToWidget(action, params) {
        this.iframe.contentWindow.postMessage({ action, params }, this.iframe.src);
    }

    get baseUrl() {
        const script = document.getElementById("directlyRTMScript");
        const link = document.createElement("a");
        let baseUrl = "";

        link.href = script.src;
        baseUrl += link.protocol;
        baseUrl += "//";
        baseUrl += link.hostname;
        baseUrl += link.port ? `:${link.port}` : "";

        return baseUrl;
    }

    get additionalQueryParameters() {
        const params = new URLSearchParams(window.location.search);
        return params.get(constants.EMAIL_TRACKING_PARAMETER)
            ? `&${constants.EMAIL_TRACKING_PARAMETER}=${params.get(constants.EMAIL_TRACKING_PARAMETER)}`
            : "";
    }

    setScrollPosition() {
        const doc = document.documentElement;
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

        this.scrollPosition = top;
    }

    saveScrollPosition() {
        this.setScrollPosition();
        document.body.style.marginTop = `${-Math.abs(this.scrollPosition)}px`;
        document.body.classList.add("directly-rtm-is-open");
    }

    restoreScrollPosition() {
        document.body.classList.remove("directly-rtm-is-open");
        document.body.style.marginTop = "";
        window.scrollTo(0, this.scrollPosition);
    }

    onPublicAPICommand([command, ...payload]) {
        switch (command) {
            case PublicAPI.CONFIG:
                this.config = assign(this.config, first(payload));
                this.setScrollPosition();
                this.createIframe();
                this.createStyles();
                this.cleanupLandingUrl();
                break;

            case PublicAPI.TRACK_EVENT: {
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.TRACK_EVENT, payload);
                });
                break;
            }

            case PublicAPI.MAXIMIZE:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.MAXIMIZE);
                });
                break;

            case PublicAPI.MINIMIZE:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.MINIMIZE);
                });
                break;

            case PublicAPI.OPEN_ASK_FORM:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.MAXIMIZE_ASK_QUESTION);
                });
                break;

            case PublicAPI.NAVIGATE:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.NAVIGATE, payload);
                });
                break;

            case PublicAPI.ASK_QUESTION:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.ASK_QUESTION, payload);
                });
                break;

            case PublicAPI.SET:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.SET_CONFIG, payload);
                });
                break;

            case PublicAPI.LOG_OUT:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.LOG_OUT);
                });
                break;

            case PublicAPI.UPDATE_METADATA:
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.UPDATE_METADATA, payload);
                });
                break;

            case PublicAPI.REROUTE: {
                const payloadData = first(payload);
                this.registerCommandInQueue(() => {
                    this.postMessageToWidget(PostMessage.REROUTE, [payloadData]);
                });
                const callback = last(payload);
                if (isFunction(callback)) {
                    this.commandQueue[command] = callback;
                }
                break;
            }

            case PublicAPI.ON_ASK_QUESTION:
            case PublicAPI.ON_LIST:
            case PublicAPI.ON_NAVIGATE:
            case PublicAPI.ON_RATED:
            case PublicAPI.ON_MESSAGE:
            case PublicAPI.ON_REJECTION:
            case PublicAPI.ON_REROUTE:
            case PublicAPI.ON_SOLVED:
            case PublicAPI.ON_READY: {
                const onReadyCallback = first(payload);

                if (isNil(onReadyCallback)) {
                    console.error(`EventListener '${command}' requires a callback method.`); // eslint-disable-line no-console
                    return;
                }

                if (!isFunction(onReadyCallback)) {
                    console.error(`EventListener '${command}' callback should be a function.`); // eslint-disable-line no-console
                    return;
                }

                this.commandQueue[command].push(onReadyCallback);
                break;
            }

            case PublicAPI.GET_STATE: {
                if (!this.isWidgetReady) {
                    console.error(
                        `Error processing '${PublicAPI.GET_STATE}'. Widget wasn't initialized yet. This command is meant to be ran only after the widget was initialised. If you want to register an event listener for when the widget is ready, please use the 'onReady' command.`
                    );
                    return;
                }

                const getStateCallback = first(payload);

                if (isNil(getStateCallback)) {
                    console.error(`'${command}' requires a callback method.`); // eslint-disable-line no-console
                    return;
                }

                if (!isFunction(getStateCallback)) {
                    console.error(`'${command}' callback should be a function.`); // eslint-disable-line no-console
                    return;
                }

                this.commandQueue[PublicAPI.GET_STATE] = getStateCallback;
                this.postMessageToWidget(PostMessage.GET_STATE);
                break;
            }

            default:
                console.error(`Unknown command: ${command}`); // eslint-disable-line no-console
                break;
        }
    }

    registerCommandInQueue(callback) {
        if (this.isWidgetReady) {
            callback();
        } else {
            this.commandQueue[PublicAPI.ON_READY].push(callback);
        }
    }

    get hasLandingQueryParam() {
        return Boolean(this.locationQueryParams[constants.LANDING_URL_KEY]);
    }

    [PostMessage.HIDE]() {
        this.wrapper.classList.add("directly-rtm");
        this.wrapper.classList.add("is-hidden");
        this.iframe.height = 0;
        this.iframe.width = 0;
    }

    [PostMessage.SHOW]() {
        this.wrapper.classList.add("directly-rtm");
        this.wrapper.classList.remove("is-hidden");
        this.iframe.height = "";
        this.iframe.width = "";
    }

    [PostMessage.MAXIMIZE]() {
        if (this.wrapper.classList.contains("is-minimized")) {
            this.wrapper.classList.remove("is-minimized");
            this.wrapper.classList.add("is-maximizing");
        }
        this.wrapper.classList.remove("is-ask-form");
        this.wrapper.classList.remove("is-hidden");
        this.wrapper.classList.add("directly-rtm");
        this.iframe.height = "";
        this.iframe.width = "";
        setTimeout(() => {
            this.wrapper.classList.remove("is-maximizing");
        }, 110);

        if (isMobileDevice() && document.documentElement.clientWidth < 668) {
            document.body.addEventListener("touchmove", event => event.preventDefault());
            this.saveScrollPosition();
        }
    }

    [PostMessage.MAXIMIZE_ASK_QUESTION]() {
        if (this.wrapper.classList.contains("is-minimized")) {
            this.wrapper.classList.remove("is-minimized");
            this.wrapper.classList.add("is-maximizing");
        }
        this.wrapper.classList.remove("is-hidden");
        this.wrapper.classList.add("directly-rtm");
        this.wrapper.classList.add("is-ask-form");
        this.iframe.height = "";
        this.iframe.width = "";
        setTimeout(() => {
            this.wrapper.classList.remove("is-maximizing");
        }, 110);

        if (isMobileDevice() && document.documentElement.clientWidth < 668) {
            document.body.addEventListener("touchmove", event => event.preventDefault());
            this.saveScrollPosition();
        }
    }

    [PostMessage.MINIMIZE]() {
        this.wrapper.classList.add("directly-rtm");
        this.wrapper.classList.add("is-minimized");
        this.wrapper.classList.add("is-minimizing");
        this.wrapper.classList.remove("is-ask-form");
        this.wrapper.classList.remove("is-hidden");
        this.wrapper.height = "";
        this.wrapper.width = "";
        setTimeout(() => {
            this.wrapper.classList.remove("is-minimizing");
        }, 90);

        if (isMobileDevice() && document.documentElement.clientWidth < 668) {
            document.body.removeEventListener("touchmove", event => event.preventDefault());
            this.restoreScrollPosition();
        }
    }

    [PostMessage.RESET_HEIGHT]() {
        this.wrapper.style.height = "";
    }

    [PostMessage.SET_HEIGHT]({ height }) {
        if (height) {
            this.wrapper.style.height = height;
        }
    }

    [PostMessage.OPEN_IMAGE_OVERLAY]() {
        this.wrapper.classList.add("is-image-overlay-open");
    }

    [PostMessage.CLOSE_IMAGE_OVERLAY]() {
        this.wrapper.classList.remove("is-image-overlay-open");
    }

    [PostMessage.EVENT_LISTENER]({ callbackName, data }) {
        forEach(this.commandQueue[callbackName], callback => {
            callback.call(undefined, data);
        });
    }

    [PostMessage.RETURN_GET_STATE]({ data }) {
        this.commandQueue[PublicAPI.GET_STATE].call(undefined, data);
        this.commandQueue[PublicAPI.GET_STATE] = undefined;
    }

    [PostMessage.RETURN_REROUTE]({ data }) {
        if (this.commandQueue[PublicAPI.REROUTE]) {
            this.commandQueue[PublicAPI.REROUTE].call(undefined, data);
            this.commandQueue[PublicAPI.REROUTE] = undefined;
        }
    }

    [PostMessage.SESSION_RESET]() {
        window.location.reload();
    }
}
