export default {
    CLOSE_IMAGE_OVERLAY: "directly:rtm:close-image-overlay",
    EVENT_LISTENER: "directly:rtm:event-listener",
    HIDE: "directly:rtm:hide",
    MAXIMIZE: "directly:rtm:maximize",
    MAXIMIZE_ASK_QUESTION: "directly:rtm:maximize-ask-question",
    MINIMIZE: "directly:rtm:minimize",
    OPEN_IMAGE_OVERLAY: "directly:rtm:open-image-overlay",
    RESET_HEIGHT: "directly:rtm:reset-height",
    IFRAME_LOADED: "directly:rtm:iframe-loaded",
    SET_HEIGHT: "directly:rtm:set-height",
    SHOW: "directly:rtm:show",
    TRACK_EVENT: "directly:rtm:track-event",
    NAVIGATE: "directly:rtm:navigate",
    ASK_QUESTION: "directly:rtm:ask-question",
    SET_CONFIG: "directly:rtm:set-config",
    LOG_OUT: "directly:rtm:log-out",
    GET_STATE: "directly:rtm:get-state",
    RETURN_GET_STATE: "directly:rtm:return-get-state",
    SESSION_RESET: "directly:rtm:session-reset",
    UPDATE_METADATA: "directly:rtm:update-metadata",
    REROUTE: "directly:rtm:reroute",
    RETURN_REROUTE: "directly:rtm:return-reroute",
};
