export default {
    CONFIG: "config",
    ON_ASK_QUESTION: "onAskQuestion",
    ON_LIST: "onList",
    ON_MESSAGE: "onMessage",
    ON_NAVIGATE: "onNavigate",
    ON_RATED: "onRated",
    ON_READY: "onReady",
    ON_REJECTION: "onRejection",
    ON_REROUTE: "onReroute",
    ON_SOLVED: "onSolved",
    SEARCH: "search",
    TRACK_EVENT: "event",
    MAXIMIZE: "maximize",
    MINIMIZE: "minimize",
    OPEN_ASK_FORM: "openAskForm",
    NAVIGATE: "navigate",
    ASK_QUESTION: "askQuestion",
    SET: "set",
    LOG_OUT: "logout",
    GET_STATE: "getState",
    UPDATE_METADATA: "updateMetadata",
    REROUTE: "reroute",
    QUESTION_STATUS_UNRESOLVED: 0,
    QUESTION_STATUS_RESOLVED: 1,
    QUESTION_STATUS_EXPIRED: 2,
};
