import bowser from "bowser";

import BrowserSupported from "../../../grails-app/assets/javascripts/src/rtm-embed/BrowserSupported";
import BrowserNotSupported from "../../../grails-app/assets/javascripts/src/rtm-embed/BrowserNotSupported";

(() => {
    const isBrowserSupported =
        !(bowser.msie && bowser.version <= 10) &&
        !(bowser.safari && bowser.version < 6);

    if (isBrowserSupported) {
        new BrowserSupported(); // eslint-disable-line no-new
    } else {
        BrowserNotSupported();
    }
})();
